









































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { Professions } from '@/plugins/podocore/modules/doctor/doctor.module';
// Import utils ------------------------------------------------------------------------------------
import { useForm, useFormFieldSelectChips, useFormFieldText, useFormObserver } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import FormFieldSelectChips from '@/components/forms/FormFieldSelectChips.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DoctorProfile',
  components: { CompositeCard, AlertError, FormFieldText, FormFieldSelectChips },
  props: {
    doctorService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rObserver = useFormObserver();

    const doctor = computed(() => {
      return (properties.doctorService.state.value as any).context.doctor;
    });

    const isLocked = ref(true);

    const defaultFormFieldsValues = computed(() => {
      return {
        firstName: doctor.value.profile.firstName as string,
        lastName: doctor.value.profile.lastName as string,
        professions: doctor.value.profile.professions as Professions[]
      };
    });

    const firstName = useFormFieldText<string>({
      key: 'firstName',
      value: defaultFormFieldsValues.value.firstName ?? '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const lastName = useFormFieldText<string>({
      key: 'lastName',
      value: defaultFormFieldsValues.value.lastName ?? '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const professions = useFormFieldSelectChips<Professions>({
      key: 'professions',
      value: defaultFormFieldsValues.value.professions ?? [],
      items: Object.values(Professions),
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    function unlock() {
      isLocked.value = false;
    }

    function lock() {
      isLocked.value = true;

      // Reset form fields values
      firstName.value = defaultFormFieldsValues.value.firstName;
      lastName.value = defaultFormFieldsValues.value.lastName;
      professions.value = defaultFormFieldsValues.value.professions;
    }

    // Lock when form submission succeeded
    watch(properties.doctorService.state, (currentState: any, previousState: any) => {
      if (
        previousState.matches({ patching: { profile: 'processing' } }) &&
        currentState.matches({
          patching: { profile: 'success' }
        })
      ) {
        lock();
      }
    });

    // Form manager
    const { submit } = useForm({ firstName, lastName, professions }, rObserver, (fields) => {
      properties.doctorService.send({
        type: 'PATCH_PROFILE',
        data: {
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
          professions: fields.professions.value
        }
      } as any);
    });

    const isSucceeded = computed(() =>
      [{ patching: { profile: 'success' } }].some((element) =>
        properties.doctorService.state.value.matches(element)
      )
    );

    const isErrored = computed(() =>
      [{ patching: { profile: 'failure' } }].some((element) =>
        properties.doctorService.state.value.matches(element)
      )
    );

    const isDisabled = computed(
      () =>
        ![{ patching: { profile: 'success' } }, { patching: { profile: 'failure' } }, 'success'].some(
          (element) => properties.doctorService.state.value.matches(element)
        ) || isLocked.value
    );

    const isLoading = computed(() =>
      [{ patching: { profile: 'processing' } }].some((element) =>
        properties.doctorService.state.value.matches(element)
      )
    );

    return {
      // Form observer
      rObserver,
      // Form fields
      firstName,
      lastName,
      professions,
      // Actions
      unlock,
      lock,
      submit,
      // Flags
      isLocked,
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
